.loading-div {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10%;
  border-radius: 0.375rem;
}

.loading-content {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: center; /* Center items horizontally */
  justify-content: center; /* Center items vertically */
  text-align: center; /* Center text inside */
  height: 100%; /* Full height to allow centering */
  gap: 20px; /* Space between the items */
}

.fixed-img-width {
  width: 50px;
}

.loading-row {
  display: flex;
  justify-content: center; /* Center items horizontally */
  align-items: center; /* Align items vertically in the center */
  width: 100%; /* Ensure the row takes full width */
  text-align: center; /* Center text */
}

.fixed-img-width-logo {
  width: 40px;
  height: 40px;
  margin-right: 10px; /* Adds space between icon and text */
}

.loading-text {
  font-size: 22px;
  margin-top: 20px;
  white-space: nowrap; /* Ensures text stays on one line */
}
